// IonSelect
ion-select {
  height: 30px;
  padding: 15px 16px 14px 16px;
  box-sizing: border-box;
  background: var(--select-element-background);
  color: var(--ion-text-color);
  border-radius: 3px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

ion-select-popover {
  background: var(--select-element-background);
  ion-list {
    padding: 0px !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    ion-item {
      color: var(--ion-text-color);
      --inner-border-width: 0 0 0 0 !important;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(var(--ion-text-color-contrast-rgb), 0.12);
      }
      ion-radio {
        opacity: 0;
      }
      &:hover {
        color: var(--ion-color-primary) !important;
        background: var(--ion-color-primary-shade);
        // border-left: 4px solid var(--ion-color-primary);
      }
      &.item-radio-checked {
        color: var(--ion-text-color);
        border-left: 4px solid var(--ion-color-primary);
        background: var(--select-popover-active-background);
      }
    }
  }
}

@media screen and (max-width:720px) {
  .popover-content {
    max-width: 170px !important;
    ion-select-popover {
      ion-list {
        ion-item {
          font-size: 10px;
          height: 30px;
          ion-label {
            margin: 0px 0px 11px;
          }
        }
      }
    }    
  }
}

// MaterialUI Select
.MuiSelect-root {
  height: 48px;
  box-sizing: border-box;
  border-radius: 6px;
  background: none !important;
}
