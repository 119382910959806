.restrictions-info-ctn {
  .restrictions-info-btn {
    --background-hover: 'none';
    --padding-end: 0px;
    --padding-start: 0px;
    cursor: pointer;
    display: inline-block;
    margin: 0 10px;
    .age-text {
      border: 1px solid var(--ion-color-primary);
      border-radius: 50%;
      //font-size: 14px;
      font-size: 12px;
      // line-height: 16px;
      padding: 10px 4px;
      font-weight: 600;
      letter-spacing: 0.01em;
      margin-left: 2px;
      color: var(--ion-color-primary);
      ion-icon {
        margin-left: -2px;
        margin-bottom: -2px;
        color: var(--ion-color-primary);
      }
    }
    .MuiSvgIcon-root {
      font-size: 32px;
    }
    .btn-text {
      margin-left: 10px;
      font-size: 12px;
      color: var(--ion-color-primary);
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      text-transform: none;
    }
  }
}

// Dialog STyles
.restrictions-info-dialog {
  background-color: #fff;
  opacity: 1;
  z-index: 1000;
  .MuiDialog-paper {
    padding: 20px;
    .dialog-title {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      font-size: 20px;
      .MuiSvgIcon-root {
        font-size: 30px;
        margin-right: 20px;
      }
    }
    .dialog-footer {
      text-align: right;
      margin-top: 20px;
    }
  }
}
@media only screen and (max-width: 720px) {
  .mob-show {
    width: 125px;
    text-align: left;
    text-transform: uppercase !important;
    display: inline-block;
    vertical-align: middle !important;
    color: #fff !important;
    font-size: 11px !important;
    white-space: normal;
  }
  .restrictions-info-btn {
    margin: 0 8px !important;
    height: auto !important;
  }
  .MuiSvgIcon-root {
    font-size: 32px;
  }
  .age-text {
    font-size: 10px;
    line-height: 20px;
    padding: 5px 5px;
  }
}

@media only screen and (max-width: 575px) {
  .restrictions-info-ctn {
    display: inline-flex;
    align-items: center;
    .restrictions-info-btn {
      //margin-bottom: 15px;
      .btn-text {
        display: none;
      }
      .mob-show {
        display: inline-block !important;
      }
      // .age-text {
      //   font-size: 14px;
      //   line-height: 20px;
      //   padding: 8px 3px;
      // }
      .age-text {
        font-size: 10px;
        line-height: 20px;
        padding: 5px 5px;
      }
      .MuiSvgIcon-root {
        font-size: 32px;
      }
    }
  }
}
.modal-footer-web {
  background: none !important;
  .modal-div-body {
    background-color: var(--ion-background-color);
    padding: 12px 6px;
    .restricted-title {
      text-transform: uppercase;
      color: var(--ion-color-link) !important;
      font-weight: bold;
      text-align: center;
      color: #0f2327;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .icon {
        vertical-align: middle;
        margin-right: 10px;
        height: 55px;
        font-size: 32px;
      }
      .name {
        font-size: 12px;
        vertical-align: middle;
        padding-top: 20px;
      }
    }
    .modal-title {
      color: var(--ion-color-link) !important;
      font-weight: bold;
      text-align: left;
      padding: 10px 10px;
      font-size: 12px;
      color: #0f2327;
    }
    .modal-body {
      color: var(--ion-color-link) !important;
      text-align: left;
      padding: 10px 10px;
      font-weight: normal;
      font-family: var(--font-family);
      font-size: 12px;
      ul,
      li {
        color: #0f2327;
        text-decoration: none;
        list-style-type: none;
        padding: 0px;
        margin-top: 5px;
        a {
          text-decoration: none;
          color: #0f2327 !important;
          padding: 4px;
          margin-top: 4px;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
    .modal-continue {
      text-align: center;
      padding: 10px 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .btn {
        height: 33px;
        border-radius: 2px;
        padding: 10px 20px;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        display: flex;
        outline: none;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        font-size: 14px;
        background: #5c996f;
        color: #fff;
        border-bottom-color: #497958;
        border-right-color: #497958;
      }
      .btn {
        color: #000;
        border: none;
        transition: all 0.3s ease;
        overflow: hidden;
      }
    }
  }
}
