.modal-close-btn {
  position: absolute !important;
  top: 8px;
  right: 8px;

  .MuiIconButton-label {
    svg {
      fill: #000;
    }
  }
}

.dark-close-btn {
  color: #000 !important;
}

.dark-colose-btn .MuiIconButton-root {
  color: var(--ion-text-light) !important;
}

.MuiDialog-paper {
  .MuiDialogContent-root {
    .modal-body {
      padding: 14px !important;
    }
  }

  .MuiDialogActions-root {
    justify-content: center;
  }
}
.MuiDialogActions-root {
  justify-content: center !important;
}
