@import url('https://fonts.googleapis.com/css?family=Tahoma');
@import url('https://fonts.googleapis.com/css?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,300,700');
@import url('https://fonts.googleapis.com/css?family=Lato:300,300italic,400,400italic,700,700italic,900,900italic');
@import url('http://fonts.cdnfonts.com/css/apollo');
@import '../src/assets/global_styles/index.scss';
@import './theme.css';
@import './scorcardtheme.css';


.Toastify__toast-container--top-center {
  top: 3em;
}

html {
  margin: 0;
  padding: 0;
  overflow: hidden;

  font-weight: normal;
  font-size: 12px;
}

body {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 12px;
}

.dev-tools-msg-modal {
  .modal-content-ctn {
    background: var(--ion-background) !important;
    border: 5px solid var(--ion-color-primary);

    .dev-tools-warning-msg {
      padding: 20px;
      font-size: 24px;
      color: #fff;
    }
  }
}
