.login-form-ctn {
  .login-form-btn {
    margin-right: 10px;
    margin-top: -2px;
    margin-bottom: 8px;
    height: 25px;
    font-size: 10px;
    .MuiButton-label {
      font-family: var(--font-family);
      text-transform: capitalize;
      color: var(--ion-text-light);
      font-size: 12px;
      font-weight: bold;
    }
  }
  .login-input-field {
    height: 25px;
    max-width: 130px;
    margin: auto 4px;
    input {
      height: 14px;
      width: 130px;
      font-size: 12px !important;
      padding: 5px !important;
    }
    input::placeholder {
      color: #000;
      font-size: 12px;
      font-weight: bolder;
    }
  }
}

.mob-login-ctn {
  margin: 0px;
  text-align: center;
  // width: 300px;
  .input-field {
    margin-bottom: 5px;
    width: 100%;
    height: 36px;
    input {
      max-width: 100%;
      font-size: 12px;
      padding: 8px 15px;
    }
  }
  .login-demoLogin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .login-btn {
      width: 130px;
      background: #183f45 !important;
      margin-top: 15px;
      border: none;
      min-height: 30px;
      padding: 0px 12px;
      line-height: 30px;
      font-size: 14px;
      .MuiButton-label {
        color: var(--ion-color-primary);
        font-size: 14px;
        font: inherit;
      }
    }
    .login-without-demoLogin {
     width: 100%; 
    }
  }
  .msg-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    .msg {
      margin-top: 4px;
      text-align: center;
      color: #fff;
      font-size: 13px;
      opacity: 0.7;
      cursor: pointer;
    }
  }
}


@media screen and (min-width: 721px) and (max-width: 900px) {
  .login-form-ctn {
    .login-input-field {
      max-width: 150px;
    }
  }
}

.login-err-msg {
  color: var(--ion-color-danger) !important;
  font-size: 12px;
  margin-top: 0px;
  margin-left: 20px;
}

.valid-field {
  input {
    padding: 0px !important;
    padding-left: 16px !important;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 2px;
    .MuiInputAdornment-positionEnd {
      .MuiIconButton-root {
        padding: 0px;
        .MuiIconButton-label {
          font-weight: bolder;
          font-family: sans-serif;
          letter-spacing: 1px;
        }
      }
    }
  }
}

.person-icon {
  margin-top: 4px;
}

.footer-links-list {
  margin: 0px 20px;
  text-align: center;
  word-spacing: -1px;
  a {
    color: #000;
    font-size: 10px;
  }
}

.login-root {
  background: url(../../assets/images/home/lotusLgBg.jpg) center center;
  background-size: 20%;
  overflow-y: auto;
  height: 100%;
  //padding-bottom: 100px;
  position: fixed;
  left: 0;
  top: 0;
  //width: 100vw;
  width: 100%;
  //height: 90vh;
  .login-footer-container {
    display: block;
    height: 40px;
    position: relative;
  }
  .login-footer-unfolded {
    max-height: calc(100vh - 20px);
    color: #000000;
    background: #ffffff;
    padding: 20px;
    margin-bottom: calc(-1 * 8px);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: absolute;
    left: 0;
    bottom: 0;
    min-height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    //transition: background .1s ease-in-out;
    text-align: center;
    .header {
      text-align: left;
      .restricted-territories {
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
        text-align: left;
        .MuiSvgIcon-root {
          color: #000;
          margin: 0 8px;
          font-size: 32px;
          text-align: center;
          display: inline-block;
          vertical-align: middle;
        }
        .ico-txt {
          color: #000000;
          text-transform: uppercase;
          text-align: center;
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
        }
      }
    }
    .details {
      height: 280px;
      text-align: left;
      font-size: 13px;
      overflow: hidden;
      transition: height 0.1s ease-in-out;
      p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }
      div {
        .title {
          text-transform: uppercase;
          text-align: center;
          padding-top: 15px;
          font-size: 14px;
        }
        p {
          display: block;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          font-size: 14px;
          text-align: center !important;
        }
      }
      .links {
        margin: 0;
        padding: 0;
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        //padding-inline-start: 40px;
        li {
          list-style: none;
          margin-bottom: 12px;
          display: list-item;
          text-align: -webkit-match-parent;
          a {
            display: block;
            padding: 4px 8px;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            color: #000000;
            border: 1px solid #000000;
            transition: opacity 0.1s ease-in-out;
            background-color: transparent;
          }
        }
      }
    }
  }
  .login-footer {
    // background: #0f2327;
    // margin-top: 90vh;
    // height: 10vh;
    display: flex;
    // color: var(--ion-color-primary);
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background: #0f2327;
    color: #fff;
    line-height: 60px;
    //padding: 20px;
    margin-top: 0;
    z-index: 2;
    .gamecareicon {
      height: 40px;
    }
  }
  .center-content {
    // position: absolute;
    // top: 45%;
    // left: 50%;
    // display: flex;
    // flex-direction: column;
    // transform: translate(-50%, -50%);
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%
    );
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: -1%;
    .login-form {
      width: 280px;
      margin: 0 auto;
      text-align: center;
    }
    .title-logo {
      // display: flex;
      // justify-content: center;
      margin-bottom: 20px;
      .logo {
        // height: var(--logo-xl-height);
        // width: var(--logo-xl-width);
        // margin-bottom: 10px;
        background: url(../../assets//images/brand/lotusbrand.png) no-repeat
          center center;
        background-size: contain;
        width: 100%;
        height: 50px;
      }
    }
  }
}

@media (max-width: 720px) {
  .login-root {
    background: url(../../assets/images/home/login_bg.jpg) center center;
    // padding-bottom: 100px;
    // background-size: 50%;
    // position: fixed;
    // left: 0;
    // top: 0;
    // width: 100vw;
    // height: 100vh;
    background-size: 75%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 8px;
    .center-content {
      padding: 0px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      .login-form {
        width: 100%;
        .title-logo {
          margin: 0px;
          .logo {
            background: url(../../assets/images/brand/lotusBrand.svg) no-repeat
              center center;
            background-size: contain;
            position: relative;
            width: 200px;
            height: 100px;
            margin: 0 auto;
          }
        }
      }
    }
    .login-footer {
      background: none;
      // margin-top: 85vh;
      // height: 10vh;
      display: flex;
      color: var(--ion-color-primary);
      justify-content: space-between;
      line-height: 20px !important;
      .gamecareicon {
        width: 32px;
        height: 32px;
        margin: 0 8px;
      }
    }
  }
  .mob-login-ctn {
    // margin: 0px;
    // text-align: center;
    // width: 340px;
    .input-field {
      margin: 0 0 8px;
      width: 100%;
      height: 39px;
      input {
        max-width: 100%;
        padding: 8px;
      }
      .MuiOutlinedInput-root{
        border-radius: 0px !important;
        height: 31px;
      }
    }
    .login-demoLogin {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .login-btn {
        width: 100%;
        //background: #183f45 !important;
        margin: 0 0 15px;
        height: 36px;
        border-radius: 2px;
        background: #183f45 !important;
        text-transform: none;
        box-shadow: none;
        cursor: default;
        opacity: 1;
        .MuiButton-label {
          //color: var(--ion-color-primary);
          //font-size: 14px;
          color: #ffffff !important;
          font-size: 13px;
        }
      }
      .login-without-demoLogin {
       width: 100%; 
      }
    }
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
  .mob-login-ctn {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    width: 300px;
    .input-field {
      margin: 10px auto;
      width: 100%;
      input {
        max-width: 100%;
      }
    }
    .login-btn {
      width: 100%;
      background: #183f45 !important;
      margin-top: 4px;
      .MuiButton-label {
        color: var(--ion-color-primary);
        font-size: 14px;
      }
    }
  }
}
