.MuiPopover-root {
  .MuiPopover-paper {
    background: var(--ion-popover-background);
    top: 288px !important;
    .MuiList-root,
    .MuiMenu-list {
      padding: 0px;
      .MuiListItem-root,
      .MuiMenuItem-root {
        color: var(--ion-text-color);
        font-size: 12px;
        height: 38px;
        padding-left: 16px;
        &:not(:last-child) {
          border-bottom: 1px solid
            rgba(var(--ion-text-color-contrast-rgb), 0.12);
        }
        &:hover {
          background: #fff;
        }
        &.Mui-selected {
          color: var(--ion-text-color-contrast);
          background: #fff;
        }
      }
    }
  }
}
.user-popover-list {
  border-radius: 4px !important;
  padding-bottom: 0px !important;
}

.popover-content {
  border-radius: 8px !important;
}
.MuiListItem-root {
  border-bottom: 1px solid #e0e6e6;
  padding: 10px 10px !important;
}

ion-popover {
  ion-list {
    background: var(--ion-popover-background) !important;
    border-radius: 4px !important;
    .ion-list-item {
      font-size: 11px;
      height: 20px;
      max-width: 225px;
      border-bottom: 1px solid #e0e6e6;
      .item-native {
        height: 20px !important;
        .item-inner {
          height: 20px !important;
          .input-wrapper {
            height: 20px !important;
          }
        }
      }
    }
  }
}
// .sc-ion-popover-ios-h{
//   width: 140px!important;
// }
// .popover-content {
//   width: 145px !important;
//   // left: 1040.8px !important;
// }
