.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      .modal-title {
        .MuiTypography-root {
          font-size: 24px !important;
          color: #000;
          font-family: var(--font-family);
        }
      }

      .MuiDialogTitle-root {
        background: #fff !important;
        color: #000 !important;
        text-align: center;
        height: auto;
        @media screen and (min-width: 1200px) {
          height: 50px;
        }
        .MuiTypography-root {
          color: #000;
        }
      }
      .MuiIconButton-root {
        position: absolute;
        top: 8px;
        right: 8px;
        opacity: 1;
        .MuiButton-root .MuiButton-label {
          color: #000;
        }
      }

      .light-bg-title {
        background: var(--ion-header-background) !important;

        text-align: center;
        height: 80px;
        .MuiTypography-root {
          color: #000;
        }
      }

      .MuiDialogContent-root {
        background: var(--dialog-content-background);
        padding: 10px 28px;

        .MuiButton-containedPrimary {
          background: var(--ion-header-background) !important;
          color: #000;
          text-transform: none;
          font-weight: 600;
          width: 100%;.MuiButton-root .MuiButton-label {
            color: var(--ion-color-primary) !important;
          }
        }
        .MuiButton-containedPrimary:hover {
          background: var(--ion-header-background) !important;
          color: var(--ion-color-primary);
        }
      }
      .MuiDialogActions-root {
        justify-content: center !important;
      }
    }
  }
}

.popover-content {
  border-radius: 0 !important;
  background-color: #fff;

  .MuiListItem-root {
    border-bottom: 1px solid #e0e6e6;
    padding: 4px 10px;
  }

  .MuiList-root {
    padding-top: 0;
    background-color: #fff;

    .MuiButtonBase-root {
      width: 250px;
      height: 28px;
      background-color: #7e97a7;
      .MuiButton-label {
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}
