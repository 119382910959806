.full-page {
  width: 100%;
  display: inline-flex;
  padding-right: 2%;
  .compt-menu-section {
    width: 15%;
  }
  .sc-ion-popover-md-h {
    --width: 175px;
  }
  button-inner {
    --color: #000;
  }
}

@media screen and (max-width: 720px) {
  .full-page {
    margin-bottom: 50px !important;
    .compt-menu-section {
      display: none;
    }
  }

  .full-page .reports-ctn {
    width: 100% !important;
  }
}

.full-page .reports-ctn {
  // width: 85%;
  // padding: 0 10px;
  // padding-bottom: 50px;
  .title-row {
    color: #0f2327;
    //border-bottom: 1px solid var(--table-border-color);
    border-bottom: 1px solid rgba(15, 35, 39, 0.4);
    .title {
      color: #0f2327;
      font-size: 24px;
      line-height: 36px;
      margin: 0px;
      padding: 10px 0 10px 0;
    }
  }
  .no-data-row {
    background-color: var(--table-body-background);
    width: 100%;
    border-bottom: 0px !important;
    color: var(--ion-text-color);
    height: 40px;
    padding-top: 15px;
    font-family: var(--ion-font-family);
    text-align: center;
  }
  .no-data-row-margin {
    background-color: var(--table-body-background);
    width: 100%;
    border: 1px solid var(--table-border-color) !important;
    color: var(--ion-text-color);
    height: 8px;
    padding-top: 10px;
  }
  .MuiPaper-root {
    margin-top: 1rem;
    background: inherit;
  }
}
@media only screen and (min-width: 1200px) {
  .reports-ctn {
    // width: 85%;
    // padding-bottom: 50px;
    .title-row {
      //border-bottom: 2px solid #ccc;
      display: flex;
      // justify-content: left;
      justify-content: space-between;
      //margin-bottom: 10px;
      //padding: 10px 0 10px 0;
      border-bottom: 1px solid rgba(15, 35, 39, 0.4);
      .title {
        font-size: 24px;
        line-height: 36px;
      }

      .balance-div {
        margin-left: auto;
        width: 300px;
        position: relative;
        height: 100%;
        vertical-align: top;
        .MuiAccordion-root .MuiAccordionSummary-root {
          margin-top: 13px !important;
        }
        .right-side-ctn {
          display: block;
          position: absolute;
          top: 50%;
          margin-top: -13px;
        }
      }
    }
    .header-ctn {
      padding-top: 20px;
      ion-row {
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }

      .excel-btn {
        font-weight: bold;
        color: var(--ion-color-text-contrast);
        margin-top: 12px;
      }
    }

    .content-ctn {
      ion-row {
        width: 100%;
        background: transparent;
        color: var(--ion-color-link);
      }
      .err-msg {
        padding: 12px;
      }
    }
    .filters-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .clim-action-web {
        margin-top: 0.6rem;
      }
      .filter-item {
        margin-left: 0.6rem;
        .filter-label {
          font-size: x-small;
        }
      }
      .search-filter {
        margin-left: 0.6rem;
        margin-right: auto;
      }
      .acm-btn-add-account {
        font-size: 13px;
        color: var(--ion-color-text-contrast);
        font-weight: bold;
      }
      .select-filter {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
        max-width: 220px;
        .select-filter-label {
          font-size: x-small;
          color: var(--ion-text-color);
          margin-left: 0.3rem;
          margin-right: 0.3rem;
        }
        .select-filter-control {
          margin-top: 0.1rem;
          min-width: 180px;
          max-width: 220px;
          background-color: #fff;
          border-radius: 3px;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
          border: 1px solid var(--table-border-color);
        }
        .nselect-filter-control {
          border: none;
          height: 36px;
          background-color: #fff;
        }
      }
      .date-filter {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
        .date-filter-label {
          font-size: x-small;
          color: var(--ion-text-color);
          margin-left: 8px;
        }
        .date-filter-control {
          // .MuiIconButton-label {
          //   display: none;
          // }
          margin-top: 0%;
          background-color: var(--ion-text-light);
          font-size: small;
          margin-left: 8px;
          border-radius: 2px;
          border: 1px solid var(--table-border-color);
          // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
          .MuiInputBase-root {
            height: 30px;
            width: 120px;
            border-radius: 2px;
            // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
            .MuiInputBase-input,
            .MuiOutlinedInput-input {
              padding: 0px;
              font-size: 10px;
              margin-left: 6px;
            }
          }
        }
      }
    }

    .content-ctn {
      margin-top: 2rem;
      background-color: transparent;
      border-radius: 6px;

      &.light-bg {
        background-color: var(--reports-bg-color);
      }
    }

    .header-ctn,
    .content-ctn {
      .points-type-toggle {
        background-color: transparent;
        border: 2px solid #636c89;
        display: inline-flex;
        height: 48px;
        border-radius: 4px;
        .MuiBottomNavigationAction-root {
          color: var(--ion-text-color);
          font-weight: 500;
          font-size: 16px;
          padding: 0px !important;
          &.Mui-selected {
            color: var(--ion-color-primary);
            background: var(--ion-color-success);
          }
        }
      }
    }
    .ion-button {
      width: 100%;
      --background: #183f45 !important;
      color: var(--ion-color-primary);
    }

    .tbl-ctn .MuiTableContainer-root .MuiTable-root .MuiTableHead-root {
      background: #ddd !important;
      font-size: 12px !important;
      border: 0px;
      .MuiTableRow-head .MuiTableCell-head {
        font-size: 12px !important;
        padding: 2px;
        text-align: left;
        font-weight: normal;
        border: 0px;
        padding-left: 1%;
        font-weight: bold;
        color: #0f2327 !important;
      }
    }

    .tbl-ctn {
      .MuiTableBody-root {
        border: 1px solid #ccc;
        .MuiTableRow-body {
          background-color: #fff;
          font-family: var(--font-family);
        }
        .MuiTableCell-body {
          color: var(--ion-text-color);
          border: 1px solid #ccc;
          cursor: default;
          font-size: 12px;
          max-width: 150px;
          // background-color: #fff;
        }

        font-family: var(--font-family);
        .MuiSvgIcon-root {
          color: var(--ion-text-color);
        }

        .back-odd-row {
          background-color: var(--back-odd-background);
        }

        .lay-odd-row {
          background-color: var(--lay-odd-background);
        }

        .profit-bet {
          color: var(--ion-color-success) !important;
        }

        .loss-bet {
          color: var(--ion-color-lost) !important;
        }
      }
      .MuiTableCell-root {
        font-family: var(--font-family);
        padding: 6px 8px;
        text-align: left;
      }
    }

    .MuiPaper-root .MuiTableBody-root .MuiTableCell-body {
      background-color: #fff;
      color: var(--input-text-color);
      &.profit {
        color: var(--ion-color-success) !important;
      }
      &.loss {
        color: var(--ion-color-danger) !important;
      }
    }
  }
}

@media (min-width: 721px) and (max-width: 1199px) {
  .reports-ctn {
    .header-ctn {
      .page-title,
      .img-page-title-row {
        padding-left: 20px !important;
        margin-top: 0.3rem;
        display: inline-flex;
        align-items: center;
        .title-image {
          height: 21px;
          width: 21px;
        }
        .title {
          margin-left: 0.3rem;
          font-size: 20px;
        }
      }
      .page-title {
        margin-right: auto;
        color: var(--page-titles-color);
        font-weight: normal;
      }
    }

    .filters-row {
      margin-top: 0.2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .filter-item {
        margin-left: 0.6rem;
        flex: 0 0 16%;
        .filter-label {
          font-size: x-small;
        }
      }
      .search-filter {
        margin-left: 0.6rem;
        margin-right: auto;
        flex: 0 0 16%;
      }
      .select-filter {
        margin: 0.3rem;
        flex: 0 0 16%;
        .select-filter-label {
          font-size: 10px;
          color: var(--ion-text-color);
        }
        .select-filter-control {
          margin-top: 0.1rem;
          font-size: 10px;
          background-color: var(--ion-text-light);
          border-radius: 3px;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        }
        .nselect-filter-control {
          border: none;
          height: 36px;
          background-color: var(--ion-text-light);
        }
      }
      .date-filter {
        margin: 0.3rem;
        flex: 0 0 16%;
        .date-filter-label {
          font-size: 10px;
          color: var(--ion-text-color);
        }
        .date-filter-control {
          margin-top: 0%;
          background-color: var(--ion-text-light);
          font-size: small;
          margin-left: 8px;
          border-radius: 3px;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
          .MuiInputBase-root {
            height: 30px;
            border-radius: 3px;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
            .MuiInputBase-input,
            .MuiOutlinedInput-input {
              font-size: 10px;
              padding: 4px 0px;
            }
          }
        }
      }
      .filter-label {
        font-size: 10px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .admin-module-ctn {
    padding-bottom: 25px;
  }
  .reports-ctn {
    margin: 10px 0px !important;
    .header-ctn,
    .content-ctn {
      padding: 0 8px;
      .points-type-toggle {
        border: 1px solid #636c89;
        height: 32px;
        border-radius: 4px;
        .MuiBottomNavigationAction-root {
          font-size: 14px;
          line-height: 17px;
          min-width: 70px;
        }
      }
    }
    .header-ctn {
      .page-title {
        margin-right: auto;
        color: var(--reports-title-color);
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: normal;
        span {
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .excel-btn {
        font-weight: bold;
        color: var(--ion-color-text-contrast);
        margin-top: 12px;
      }
    }
    .filters-row {
      display: flex;
      flex-wrap: wrap;
      .filter-item {
        margin: 0.3rem;
        flex: 0 0 45%;
        .filter-label {
          font-size: x-small;
        }
        .filter-control {
          font-size: 10px;
          background-color: var(--ion-text-light);
        }
      }
      .search-filter {
        margin-left: 0.6rem;
        margin-right: auto;
        flex: 0 0 100%;
        .clear-search {
          font-size: 10px;
        }
      }
      .select-filter {
        margin: 0.3rem;
        flex: 0 0 45%;
        .select-filter-label {
          font-size: 10px;
          color: var(--ion-text-color);
        }
        .select-filter-control {
          margin-top: 0.1rem;
          width: 100%;
          font-size: 10px;
          border-radius: 3px;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        }
        .nselect-filter-control {
          border: none;
          height: 36px;
        }
        .MuiInputBase-root .MuiInputBase-input,
        .MuiInputBase-root .MuiOutlinedInput-input {
          border-radius: 3px;
          font-size: 10px;
        }
      }
      .date-filter {
        margin: 0.3rem;
        flex: 0 0 45%;
        .date-filter-label {
          font-size: 10px;
          color: var(--ion-text-color);
        }
        .date-filter-control {
          margin-top: 0%;
          background-color: var(--ion-text-light);
          font-size: small;
          border-radius: 3px;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
          .MuiInputBase-root {
            height: 30px;
            width: 100%;
            border-radius: 3px;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
            .MuiInputBase-input,
            .MuiOutlinedInput-input {
              font-size: 10px;
              padding: 4px 0px;
            }
          }
        }
      }
      .filter-label {
        font-size: 10px;
      }
    }
    .header-ctn {
      padding-left: 0px;
      padding-right: 0px;
      .page-title {
        font-size: 26px;
        line-height: 22px;
        padding-left: 10px !important;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 1rem;
        color: var(--page-titles-color);
        span {
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .excel-btn {
          font-weight: bold;
          color: var(--ion-color-text-contrast);
          margin-top: 12px;
        }
      }

      .img-page-title-row {
        margin-top: 0.3rem;
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        display: flex;
        align-items: baseline;
        .title-image {
          height: 21px;
          width: 21px;
        }
        .title {
          font-size: 26px;
          line-height: 22px;
          margin-left: 0.3rem;
        }
      }

      .summary-card {
        ion-row:not(:last-child) {
          margin-bottom: 10px;
        }
        .summary-expand-toggle {
          text-align: center;
          .summary-expand-toggle-btn {
            color: var(--ion-text-color);
            text-transform: none;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.02em;
            height: 20px;
            ion-icon {
              color: var(--ion-text-color);
              height: 18px;
            }
          }
        }
      }
    }

    .content-ctn {
      .table-ctn,
      .tbl-ctn {
        .MuiTableCell-root {
          padding: 8px;
        }
      }
    }

    .txt-info {
      font-weight: bold;
      font-size: 13px;
    }

    .mob-fs-13 {
      font-weight: bold !important;
      font-size: 13px;
      text-transform: capitalize;
    }

    .mob-fs-14 {
      font-weight: bold !important;
      font-size: 14px;
      text-transform: capitalize;
    }

    .mob-fs-16 {
      font-weight: bold !important;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
  .text-ellipsis {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.icon-text-section {
  display: inline-flex;
  .icon-div {
    background: var(--table-header-background);
    border-radius: 25%;
    font-size: 8px;
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;
    padding: 10px;
    height: fit-content;
    .img {
      width: 20px;
      height: 20px;
    }
  }
}

.filters-row {
  input {
    border: none !important;
    border-radius: 0px !important;
  }
}
.date-filter-control {
  .MuiInputBase-root {
    border: none;
    .MuiInputBase-input,
    .MuiOutlinedInput-input {
      color: var(--ion-text-color) !important;
      border: none !important;
      font-size: small;
      margin-left: 8px;
    }
  }
}

span,
div {
  color: var(--ion-text-color);
}
@media screen and (max-width: 720px) {
  .full-page .reports-ctn .title-row .title {
    padding-left: 8px !important;
  }
}
