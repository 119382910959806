.MuiInputBase-root {
  background: var(--input-background-color) !important;
  color: var(--input-text-color) !important;
  font-size: 14px;
  height: 38px;
  padding: 0;
  box-sizing: border-box;
  border-radius: 3px;

  .MuiFormControl-root {
    background: var(--input-background-color) !important;
    border-radius: 3px;
  }

  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    font-size: 16px;
    padding: 4px 16px;
    max-width: 170px;
  }
  .MuiSvgIcon-root {
    color: var(--input-text-color);
  }

  .MuiFormHelperText-contained {
    margin: 0px !important;
    background: none !important;
  }

  .Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}

/* Input Styles */

// .MuiInputBase-root .MuiFormControl-root,
// .MuiOutlinedInput-root,
// input {
//   background: var(--input-background-color) !important;
//   color: var(--input-text-color) !important;
//   border-radius: 8px;
// }

.sc-ion-input-md-h input {
  border-radius: 3px !important;
}

.sc-ion-input-md-h, input {
  border-radius: 3px !important;
  border: 1px solid var(--input-border-color);
  background-color: var(--input-background-color);
}

.input-label {
  color: var(--ion-text-color);
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;
}


.modal-content-ctn {
  .date-control,
  .input-control {
    border: 1px solid var(--filter-controls-border);
  }
}

.MuiFormControlLabel-label{
  color: var(--ion-text-color);

}
.form-input {
  display: inline-grid;
  margin-top: 2rem;
  width: 100%;
}