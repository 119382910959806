.accept-terms-checkbox {
  color: var(--ion-text-color) !important;
  position: relative !important;
}
.submit-form-btn {
  width: 100%;
  background-color: #183f45 !important;
  margin-top: 4px;
  .MuiButton-label {
    color: var(--ion-color-primary) !important;
    font-size: 14px;
  }
}
.full-page {
  .reports-ctn {
    padding-left: 2% !important;
    .change-pass-ctn,
    form {
      background-color: #fff !important;
      min-height: 75vh;
      width: 30%;
      @media only screen and (max-width: 720px) {
        width: 92%;
        padding-left: 4%;
        padding-right: 4%;
      }
      margin-top: 10px;
      padding-left: 2%;
      padding-right: 2%;
      .form-input {
        .input-label {
          font-size: 12px;
          font-family: var(--font-family);
        }
      }
      .condition-ctn {
        margin-top: 25px;
        .title {
          font-size: 16px;
          line-height: 15px;
          color: #333333;
        }
        .detail {
          font-size: 12px;
          color: #ff0000;
          font-weight: bold;
          margin-top: 8px;
          font-family: var(--font-family);
          line-height: 20px;
        }
      }
      .MuiDialogActions-root {
        justify-content: flex-end !important;
        .MuiButton-label {
          color: #000;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .full-page {
    .reports-ctn {
      .title-row {
        display: flex;
        justify-content: space-between;
        border-bottom: 0px !important;
        .title {
          font-size: 18px !important;
          line-height: 30px;
          font-weight: bold;
          font-family: var(--font-family);
          color: #000;
        }
      }
      .change-pass-ctn,
      form {
        background-color: #fff !important;
        min-height: 50vh;
        width: 100%;
        margin-top: 0px;
        padding-left: 2%;
        padding-right: 4%;
        .form-input {
          margin-top: 0.5rem;
        }
        .MuiInputBase-root .MuiInputBase-input,
        .MuiInputBase-root .MuiOutlinedInput-input {
          font-size: 13px;
          font-weight: 600;
          font-family: var(--font-family) !important;
        }
        .form-input {
          .input-label {
            font-size: 12px;
            font-family: var(--font-family);
            margin: 0px;
          }
        }
        .condition-ctn {
          margin-top: 20px;
          .title {
            font-size: 13px;
            line-height: 13px;
            color: #000;
          }
          .detail {
            font-size: 11px;
            color: #f53d3d;
            font-weight: normal;
            margin-top: 8px;
            font-family: var(--font-family);
            line-height: 20px;
          }
        }
        .MuiDialogActions-root {
          justify-content: flex-end !important;
          .MuiButton-root {
            background-color: #777777;
            width: 180px;
            height: 34px;
            border-radius:4px ;
            .MuiButton-label {
              color: #fff;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
