.events-table-ctn {
  width: 100%;
  .banner-container {
    background: url('../../assets/images/sportsbook/banners/cricket-kohli.png');
    background-size: cover;
    height: 180px;
    width: 100%;
    display: flex;
    align-items: center;
    // border-radius: 6px;
    overflow: hidden;
    cursor: default;
    .sport-image {
      height: 140px;
      margin-left: 5%;
      &.inplay-image {
        height: 80px;
      }
    }

    .banner-nav-link {
      color: #fff;
      text-decoration: none;
    }

    .eventType-name {
      font-family: var(--font-family);
      font-style: normal;
      font-weight: normal;
      font-size: 82px;
      line-height: 98px;
      letter-spacing: 0.1em;
      color: #f8f8f8;
      margin-left: 12%;
      margin-right: 22%;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      align-items: center;
      .inplay-live-icon {
        height: 58px;
        width: 58px;
        margin-right: 21px;
        margin-top: -8px;
      }
    }
  }

  .eventType-accordion {
    box-shadow: none;
    margin: 0px !important;
    margin-top: 8px !important;
    .eventType-header {
      font-family: var(--font-family);
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: var(--accordian-headers-color) !important;
      background: linear-gradient(180deg, #2d495c 0%, #243a48 100%);
      padding: 6px;
      height: 26px !important;
      display: flex;
      align-items: center;
      overflow: hidden;
      font-weight: 500;

      .sport-inplay-icon {
        height: 24px;
        width: 24px;
        margin-right: 12px;
      }

      .MuiAccordionSummary-expandIcon {
        opacity: 1;
        .expand-icon {
          color: var(--accordian-headers-color) !important;
        }
      }
    }
  }
  .inplay-events-tbl-container,
  .events-table-content {
    .events-table .heade-row {
      .MuiTableCell-root {
        padding: 7px;
        font-size: 12px;
        color: var(--ion-color-link);
        font-family: var(--font-family) !important;
        font-weight: bold;
        text-align: left;
      }
      .MuiTableCell-alignCenter {
        text-align: center !important;
      }
    }
    padding: 0px;

    .teams-cell {
      cursor: pointer !important;
      .teams {
        width: 100%;
        .team-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: space-between;
          font-family: var(--font-family);
          font-weight: bold !important;
          align-items: center;

          .inplay-badge,
          .team-badge {
            max-width: 160px !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 160px;
            color: var(--sports-team-name-color);
            font-family: var(--font-family) !important;
            // padding-top: 2px;
            font-weight: bold;
            .fav-icon {
              margin-right: 5px;
              margin-left: 7px;
              width: 14px;
              cursor: pointer;
            }
            .nav-link {
              color: var(--sports-team-name-color);
              font-family: var(--font-family) !important;
              font-weight: bold;
              text-decoration: none;
              align-items: center;
              padding-top: 4px;
              margin-left: 7px;
              .fav-icon {
                margin-right: 5px;
                margin-left: 2px;
                width: 14px;
                cursor: pointer;
              }
              .fav-icon1 {
                margin-right: 5px;
                margin-left: 2px;
                width: 14px;
                cursor: pointer;
              }
            }
            .nav-link:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .market-icons {
        margin-left: auto;
      }
      // }
    }
  }

  .table-ctn,
  .tbl-ctn {
    .MuiPaper-root {
      margin-top: 0;
      border-radius: 0;
    }

    .MuiTableContainer-root {
      background: transparent;
      .MuiTable-root {
        .MuiTableHead-root {
          border: none;
          .MuiTableRow-head {
            .MuiTableCell-head {
              white-space: nowrap;
              // font-weight: 600;
              font-size: 12px;
              font-family: var(--font-family);
            }
          }
        }

        .MuiTableBody-root {
          // border: 1px solid #ddd;
          border: 0px;
          background-color: var(--ion-color-primary);
          .MuiTableRow-root {
            .schedule-cell {
              max-width: 84px;
              white-space: break-spaces;
            }
          }

          .MuiTableRow-root:hover {
            background-color: #eae8e5 !important;
          }

          .MuiTableCell-root {
            // padding: 10px 6px;
            font-size: 12px;
            border: 1px solid rgba(15, 35, 39, 0.2);
            text-align: center;
            vertical-align: middle;
            padding: 0px;
            width: 100%;

            .odds-block {
              display: flex;
              width: 100%;
              flex-direction: row;
              justify-content: space-between;
              // justify-content: center;
              align-items: center;
              .exch-odd-view {
                min-width: 30px;
                max-width: 61px;
                // max-width: 62px;
                font-family: var(--font-family);
                // border-left: 1px solid #ddd;
              }

              .back-odd,
              .lay-odd {
                cursor: default !important;
                min-width: 60px;
                padding: 0px;
                font-style: normal;
                vertical-align: middle;
                .price {
                  font-family: var(--font-family);
                  color: var(--ion-color-link);
                  font-size: 12px;
                }
                .size {
                  display: none;
                  opacity: 0.7;
                }
              }
            }

            .pin-icon {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .live-link {
      margin-right: 4px;
      color: #1d7f1e;
      font-size: 10px;
      font-weight: normal;
      vertical-align: middle;
      .live-img {
        // height: 14px;
        // width: 14px;
        vertical-align: middle;
        // margin-left: 4px;
        // margin-right: 4px;

        font-size: 15px;
        padding-right: 3px;
      }

      .genie-img {
        height: 25px;
        width: 25px;
      }
    }
    .event-time {
      margin-right: 4px;
      color: var(--ion-text-dark);
      font-size: 10px;
      font-weight: 400;
      .live-clock {
        display: flex;
        flex-direction: column;
        color: var(--ion-text-dark);
        font-size: 9px;
        font-weight: 400;
      }
    }
    .teams-cell {
      text-decoration: none;
    }
  }
}

.back-odd {
  background-color: var(--back-odd-background);
}
.lay-odd {
  background-color: var(--lay-odd-background);
}

@media (min-width: 1200px) {
  .events-table-ctn {
    display: flex;

    .events-section {
      width: 100%;
      .title-row {
        border-bottom: 1px solid rgba(15, 35, 39, 0.4);
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        font-family: var(--font-family);
        font-weight: normal;
        text-transform: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 1%;

        .title {
          // font-size: 24px;
          text-transform: capitalize;
          color: #0f2327;
          line-height: 36px;
          margin: 0px;
          padding: 10px 0 10px 0;
        }
        .betting-time {
          font-size: 12px;
          color: var(--ion-color-link);
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .sport-info-section {
      width: 24%;
      margin-left: auto;
    }
  }
  .inplay-events-table-ctn {
    flex-direction: column;
  }
}

@media screen and (max-width: 720px) {
  .events-table-ctn {
    .title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      .title {
        font-weight: 500;
        font-size: 16px;
      }
      .title-msg {
        display: none;
      }
    }
  }
}
