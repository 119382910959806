.inplay-events-table-ctn {
    .MuiTabs-root {
        min-height: 33px;
    }
    .MuiTabs-fixed, .MuiTabs-scrollable {
        border: 1px solid transparent;
        border-radius: 5px;
        height: 36px;
        background-color: transparent;
        .MuiTab-root {
            height: 33px;
            min-height: 33px;
            padding: 0px 4px;
            background: var(--ion-text-light);
            border: 1px solid var(--ion-text-color);
            opacity: 1;
    
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            text-transform: capitalize;            
            span {
                color: var(--ion-text-color);
            }
        }
        .MuiTab-root:nth-child(1) {
            border-radius: 5px 0px 0px 5px;
        }
        .MuiTab-root:nth-last-child(1) {
            border-radius: 0px 5px 5px 0px;
        }
        .MuiTab-textColorInherit.Mui-selected {
            background: linear-gradient(180deg, #2D495C 0%, #243A48 100%);
            span {
                color: var(--ion-text-light);
            }
        }
    }
}
@media (max-width: 720px) {
    .inplay-events-table-ctn {
        .MuiTabs-fixed {
            .MuiTab-root {
                background: linear-gradient(180deg, #2D495C 0%, #243A48 100%);
                border: 1px solid var(--ion-text-color);
                span {
                    color: var(--ion-text-light);
                }
            }
            .MuiTab-textColorInherit.Mui-selected {
                background: var(--ion-text-light);
                span {
                    color: var(--ion-text-color);
                }
            }
        }
    }
}