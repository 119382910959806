.MuiPickersBasePicker-container {
  background-color: var(--date-picker-container-background);
  color: var(--ion-text-color-contrast);
  // margin-top: 10px;
  .MuiPickersBasePicker-pickerView {
    .MuiPickersCalendarHeader-switchHeader {
      margin: 0px;
      padding: 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid rgba(var(--ion-text-color-contrast-rgb), 0.1);
      background: #0f2327;
      color: #fff !important;
      .MuiTypography-alignCenter {
        color: #fff !important;
        font-family: var(--font-family);
        padding: 0px;
        font-size: 15px;
        font-weight: normal;
      }
      .MuiPickersCalendarHeader-iconButton {
        background: #398696 !important;
        color: #fff !important;
        opacity: 1;
        border-radius: 3px;
        .MuiSvgIcon-root{
          color: #fff !important;
        }
      }
    }
    .MuiPickersCalendarHeader-daysHeader {
      .MuiPickersCalendarHeader-dayLabel {
        padding-top: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: var(--ion-color-primary) !important;
      }
    }
    .MuiPickersCalendar-transitionContainer {
      .MuiIconButton-root {
        color: var(--ion-text-color-contrast) !important;
        opacity: 1;
        border-radius: 4px;
      }
      .MuiPickersDay-hidden {
        color: var(--ion-text-color) !important;
      }
      .MuiPickersDay-daySelected {
        background: var(--ion-primary-gradient) !important;
        color: #fff !important;
        .MuiTypography-colorInherit {
          color: #fff !important;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .MuiPickersBasePicker-container {
    .MuiPickersBasePicker-pickerView {
      min-width: 225px;
      max-width: 230px;
      min-height: 225px;
      max-height: 230px;
      overflow: hidden;
      .MuiPickersCalendarHeader-switchHeader {
        margin: 0px;
        padding: 4px;
        .MuiPickersCalendarHeader-iconButton {
          padding: 4px;
        }
      }
      .MuiPickersDay-day {
        width: 32px;
        height: 32px;
        margin: 0px;
      }
      .MuiPickersCalendar-transitionContainer {
        min-height: 162px;
      }
    }
  }
}
