.forgot-pwd-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--ion-primary-gradient);

  .usr-input,
  .code-input,
  .pwd-input {
    display: inline-grid;
  }

  .confirm-btn {
    margin-top: 10px !important;
    max-width: 341px;
    height: 48px;
    
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
  }

  .MuiIconButton-root {
    padding: 0;
    opacity: 1;
    color: var(--ion-text-color) !important;
  }

  .error-msg {
    color: var(--ion-color-danger);
    margin-top: 20px;
    text-align: center;
  }
  .success-msg {
    color: var(--ion-color-success);
    margin-top: 20px;
    text-align: center;
  }

  .usr-input,
  .pwd-input,
  .code-input {
    margin-top: 40px;
  }

  .user-name,
  .pwd-field {
    max-width: 341px;
    height: 48px;
  }
}
